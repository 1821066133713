import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router"; 
import { LoginComponent } from "./main_app_components/login/login.component";
import { RegisterUserComponent } from "./main_app_components/register-user/register-user.component";
import { RegisterConfirmationRequiredComponent } from "./main_app_components/register-confirmation-required/register-confirmation-required.component";
import { RegisterCoachComponent } from "./main_app_components/register-coach/register-coach.component"; 
import { AnyAuthenticationGuard } from "./guards/any-authentication.guard"; 
import { AboutusPageComponent } from "./pages/aboutus/aboutus-page/aboutus-page.component";
import { FAQMainPageComponent } from "./pages/FAQ/faqmain-page/faqmain-page.component"; 
import { PasswordForgetEmailSentComponent } from "./main_app_components/password-forget-email-sent/password-forget-email-sent.component";
import { ResetPasswordComponent } from "./main_app_components/reset-password/reset-password.component";
import { UserConfirmationDoneComponent } from "./main_app_components/user-confirmation-done/user-confirmation-done.component";
import { CoachConfirmationDoneComponent } from "./main_app_components/coach-confirmation-done/coach-confirmation-done.component";
import { ConfirmationErrorComponent } from './main_app_components/confirmation-error/confirmation-error.component';
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { CorporatesComponent } from "./pages/corporates/corporates.component";
import { AssessmentFormComponent } from "./main_app_components/assessment-form/assessment-form.component";
import { NotFoundComponent } from "./main_app_components/not-found/not-found.component";

const routes: Routes = [
  { 
    path: "",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: "communicate", 
    canActivate: [AnyAuthenticationGuard],
    loadChildren: () => import('./pages/communications/communications.module').then(m => m.CommunicationsModule)
  },
  {
    path: "coaches",
    loadChildren: () => import('./pages/coaches/coaches.module').then(m => m.CoachesModule)
  },
  {
    path: "blogs", 
    loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsModule)
  },
  {
    path: "user", 
    loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: "register_user", 
    component: RegisterUserComponent
  },
  {
    path: "corporates",
    component: CorporatesComponent,
  }, 
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "assessment-form",
    component: AssessmentFormComponent,
  }, 
  {
    path: "confirm_registration",
    component: RegisterConfirmationRequiredComponent,
  },
  {
    path: "register_coach",
    component: RegisterCoachComponent,
  },
  {
    path: "about",
    component: AboutusPageComponent,
  },
  {
    path: "faq",
    component: FAQMainPageComponent,
  },
  {
    path: "forget_password",
    component: PasswordForgetEmailSentComponent,
  },
  {
    path: "reset_password",
    component: ResetPasswordComponent,
  },

  {
    path: "terms-conditions",
    component: TermsConditionsComponent,
  },
  {
    path: "user/confirmation_done",
    component: UserConfirmationDoneComponent,
  },

  {
    path: "coach/confirmation_done",
    component: CoachConfirmationDoneComponent,
  },
  {
    path: "confirmation_error/:er",
    component: ConfirmationErrorComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
  },

  // 404
  {
    path: "404",
    component: NotFoundComponent,
  },

  {
    path: "**",
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  }, 
   
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    scrollPositionRestoration: 'enabled',
    useHash: true,
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule],
}) 

export class AppRoutingModule { }
