import { Component, OnInit, HostListener } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { scan } from 'rxjs/operators';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor(    public _tranalationService: TranslationService,
    private _deviceDetector:DeviceDetectorService) { }
  IsMobile: boolean=false;
  ngOnInit() {
    this.IsMobile = this._deviceDetector.isMobile();
console.log(this.IsMobile)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    var img=document.getElementById("master-img");
    img.style.left="500px";
    console.log(img.style.left)
    if (window.innerWidth < 996) {
      this.IsMobile = true
    }
    if (window.innerWidth >= 996) {
      this.IsMobile = false
    }
  }
 

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == 'ar')
      return true

    return false
  }
}
