import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  ViewEncapsulation
} from "@angular/core";
import { ImagesService } from "src/app/services/images/images.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "src/app/services/translation.service";

@Component({
  selector: "app-image-upload-field",
  templateUrl: "./image-upload-field.component.html",
  styleUrls: ["./image-upload-field.component.css"]
})
export class ImageUploadFieldComponent implements OnInit {
  @ViewChild("hiddenInputsContainer", { static: true })
  hiddenInputsContainer: ElementRef;
  @Input() NoFilesText: string = "No Files Uploaded";
  @Input() BoundFile: HTMLInputElement;
  @Input() IsCMSControl: boolean = false;
  @Input() UploadedImageSource: string = null;
  @Input() UploadedImageId: Number = null;
  @Output() ImageUploaded: EventEmitter<UploadedImage> = new EventEmitter<
    UploadedImage
  >();

  constructor(
    private imagesService: ImagesService,
    public _tranalationService: TranslationService,
    private ToastrService: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    console.log(this.UploadedImageId);
    console.log(this.UploadedImageSource);
  }

  public async addFileClicked(event) {
    let input = document.createElement("input");
    let type = document.createAttribute("type");
    let accept = document.createAttribute("accept");
    accept.value = "image/*";
    type.value = "file";

    input.setAttributeNode(type);
    input.setAttributeNode(accept);

    this.hiddenInputsContainer.nativeElement.appendChild(input);

    input.onchange = async () => {
      if (input.value) {
        this.BoundFile = input;
        await this.uploadImage();
      }
    };

    input.click();
  }

  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;

    return false;
  }
  public HasBoundImage() {
    return this.UploadedImageId && this.UploadedImageSource;
  }

  public async uploadImage() {
    let form = new FormData();
    form.append("image", this.BoundFile.files[0]);
    let response = null;
    if (!this.IsCMSControl) {
      response = await this.imagesService.UploadImage(form).toPromise();
    } else {
      response = await this.imagesService.UploadCMSImage(form).toPromise();
    }
    console.log(response);
    if (!response.error) {
      this.UploadedImageSource = response.result.url;
      this.UploadedImageId = response.result.added_image_id;
      this.ImageUploaded.emit({
        UploadedImageId: response.result.added_image_id,
        UploadedImageUrl: response.result.url
      });
    } else {
      this.ToastrService.error(
        this.translate.currentLang == "ar"
          ? "رفع الصور غير ممكن"
          : "Unable to upload Image"
      );
    }
  }

  public removeFile(event, index) {
    this.BoundFile = null;
    this.UploadedImageSource = null;
    let inputs = this.hiddenInputsContainer.nativeElement.querySelectorAll(
      "input"
    );
    inputs.forEach(element => {
      this.hiddenInputsContainer.nativeElement.removeChild(element);
    });
  }

  public filenameOnly(path: string) {
    return path.split(/(\\|\/)/g).pop();
  }
}

export interface UploadedImage {
  UploadedImageUrl: string;
  UploadedImageId: Number;
}
