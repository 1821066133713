let baseUrl: string = "https://www.coachyou.io/api/api/";
let baseUrlTesting: string = "https://coachyou.me/ApiTesting/api/";

export const environment = {
    production: true,
    baseUrl: baseUrl,
    api_base: baseUrl,
    application_base: baseUrl,
    web: {
        "client_id": "672912706410-etqo9qfg3d2lhooaivhutakrlai7jqb5.apps.googleusercontent.com",
        "project_id": "coachyoucalendar",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_secret": "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",
        "redirect_uris": [
            "http://localhost:4200"
        ],
        "javascript_origins": [
            "http://localhost:4200"
        ]
    },
    "GoogleCalendar": {
          // Abed EMail and creditintials 
        // "ClientId": "1099292599205-l3cft3iaqctfno2j81fr48lurviaqsqj.apps.googleusercontent.com",
        // "ClientSecret": "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",
        // "Scope": "https://www.googleapis.com/auth/calendar",
        // "ApplicationName": "CoacheYouProject",
        // "User": "user", 
        // "RedirectURL": "https://coachyou.io/auth/callback",
        // "Url": "https://accounts.google.com/o/oauth2",
        // "CalendarId": "primary"


        //Coach You creditnentials 
        "ClientId": "272536796839-os8os5fkdvl8c3jt6v5i64n08nnq791r.apps.googleusercontent.com",
        "ClientSecret": "KZ7cftvbLK5jhZRFBlZ3sDV4",
        "Scope": "https://www.googleapis.com/auth/calendar",
        "ApplicationName": "CoacheYou",
        "User": "user",
        "RedirectURL": "https://coachyou.io/auth/callback",
        "Url": "https://accounts.google.com/o/oauth2",
        "CalendarId": "primary"
    },
};
// "https://coachyou.co/api"
