import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountsService } from "src/app/services/global_services/accounts.service";
import { BsDatepickerInlineConfig } from "ngx-bootstrap/datepicker/public_api";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { TranslationService } from "src/app/services/translation.service";
import { InvitationsService } from '../../services/invitations/invitations.service';
import { Title } from "@angular/platform-browser";
import { countries } from '../../data/countries';
import { ar_genders, en_genders } from '../../data/lockup';
@Component({
  selector: "app-register-user",
  templateUrl: "./register-user.component.html",
  styleUrls: ["./register-user.component.css"]
})
export class RegisterUserComponent implements OnInit {
  @ViewChild("imageInput", { static: true }) ProfileImage: ElementRef;
  phone_number1;
  registerForm: FormGroup;
  loading = false;
  isRegister = false;
  submitted = false;
  returnUrl: string;
  message: string = "";
  imagePreviewSrc = null;
  invitation_code = null;
  loadingInvitation: boolean = false;
  validInvitationCode: boolean = false;
  file;
  genders;
  allCountries = countries;
  allowedCountries: string[] = [];
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public _tranalationService: TranslationService,
    private accountsService: AccountsService,
    private ToastrService: ToastrService,
    public translate: TranslateService,
    private _invitationsService: InvitationsService,
    private titleService: Title
  ) { }

  ngOnInit() {

    this.genders = this.translate.currentLang == "ar" ? this.genders = ar_genders : this.genders = en_genders; 
    this.allCountries.forEach(x => this.allowedCountries.push(x.countryCode.toLowerCase())); 
    this.titleService.setTitle("Create a free account  | Coach you ")
    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.email, Validators.required]],
      password: ["", Validators.required],
      confirm_password: ["", Validators.required],
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      birth_of_date: ['', Validators.required],
      mobile_number: ['', Validators.required], 
      gender: [, Validators.required],
      invitation_code: [""], 
    });
     
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";

    this.registerForm.valueChanges.subscribe(() => {
      this.message = "";
    });
 
  }
  uploadImage(e) {
    if (e.target.files) {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0])
      this.file = e.target.files[0]
      reader.onload = (event: any) => {
        this.imagePreviewSrc = event.target.result
      }
    }
  }
   
  get f() {
    return this.registerForm.controls;
  }
  
  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.controls.mobile_number.status == "INVALID") {
      this.ToastrService.error("Error", this._tranalationService.getTranslationValue('MobileNumberInvalid'));
      return;
    }

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.ToastrService.error("Error", "Please fill out requiered fields");
      return;
    } 

    if (this.registerForm.controls.password.value != this.registerForm.controls.confirm_password.value) {
      alert("confirm password first");
      return;
    }

    this.loading = true;

    let payload = new FormData;

    payload.append("Email", this.registerForm.get("email").value);
    payload.append("FirstName", this.registerForm.get("first_name").value);
    payload.append("LastName", this.registerForm.get("last_name").value);
    payload.append("Password", this.registerForm.get("password").value);
    payload.append("PhoneNumber", this.registerForm.get("mobile_number").value);
    payload.append("BirthDate", new Date(this.registerForm.get("birth_of_date").value).toISOString());
    payload.append("Gender", this.registerForm.get("gender").value);
    payload.append("InvitationCode", "1");
    payload.append("ApplicationType", "1");
    payload.append("PreferedLanguage", "1");
    payload.append("Picture", this.file);
    this.isRegister = true;
    await this.accountsService.signUp(payload).toPromise().then(res => {
      if (res.errorCode == 0) {

        this.loading = false;
        this.router.navigate(["confirm_registration"], {
          queryParams: {
            email: this.registerForm.get("email").value,
            userId: res.data,
            registerType: "User"
          }
        });
      } else if (res.errorCode == 5) {
        this.loading = false;
        this.isRegister = false;
        this.ToastrService.error("Email is taken");
        return
      }
      else {
        this.loading = false;
        this.isRegister = false;
        this.ToastrService.error("Error");
      }
    }, error => {
      this.loading = false;
      this.isRegister = false;
      this.ToastrService.error("Something has went wrong");
    }) 
  }
  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;

    return false;
  }
  public openFileChooser() {
    this.ProfileImage.nativeElement.click();
  }

  async applyInvitation() {
    try {
      this.loadingInvitation = true;
      this.registerForm.controls.invitation_code.disable();
      var response: any = await this._invitationsService.CheckAvailableInvitation(this.registerForm.get('invitation_code').value).toPromise();

      if (!response.error && response.result) {
        console.log('response.result', response.result);
        this.validInvitationCode = true;
      }
      else {
        if (this.isArabic()) { this.ToastrService.error("خطأ", "Invalid Invitation Code"); }
        else { this.ToastrService.error("Error", "Invalid Invitation Code"); }
        this.registerForm.controls.invitation_code.enable();
      }
      this.loadingInvitation = false;
    }
    catch (err) {
      if (this.isArabic()) { this.ToastrService.error("خطأ", "Invalid Invitation Code"); }
      else { this.ToastrService.error("Error", "Invalid Invitation Code"); }
      this.loadingInvitation = false;
      this.registerForm.controls.invitation_code.enable();
    }
  }

  removeInvitation() {
    this.registerForm.controls.invitat83ion_code.setValue(null);
    this.registerForm.controls.invitation_code.enable();
    this.validInvitationCode = false;
  }

 


}




// {
//   "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiVXNlciIsInVzZXJJZCI6IjBhYjFjY2U5LWM2M2UtNDY2ZS1iZjI3LTA4ZGJhMjJhMjNmYyIsInVuaXF1ZV9uYW1lIjoiZGFnb3JhNzAwM0BiYWdvbmV3LmNvbSIsIm5hbWVpZCI6IjBhYjFjY2U5LWM2M2UtNDY2ZS1iZjI3LTA4ZGJhMjJhMjNmYyIsIm5iZiI6MTY5MjYyNjk1MiwiZXhwIjoxNjkyOTg2OTUyLCJpYXQiOjE2OTI2MjY5NTJ9.aC5VjPwzGhH_h0JIqtaKQTP6VHmdrbs4SjXOQ9qTutY",
//   "refreshToken": {
//       "refreshToken": "MUB4HZ4RJrt0AZfHFz5SZNVX9b6U6JjOf+Jl0cvGPBY=",
//       "validUntil": "2023-08-22T17:09:12"
//   },
//   "hasPendingCoachRequest": false,
//   "isCorporateCoachee": false,
//   "corporateImage": null,
//   "corporateName": null,
//   "corporateId": 0,
//   "forceChangePassword": false,
//   "id": "0ab1cce9-c63e-466e-bf27-08dba22a23fc",
//   "username": "dagora7003@bagonew.com",
//   "firstName": "dagora7003@bagonew.com",
//   "lastName": "dagora7003@bagonew.com",
//   "phoneNumber": "+962787452419",
//   "email": "dagora7003@bagonew.com",
//   "rate": null,
//   "picture": null,
//   "dateOfBirth": "2023-08-15T17:08:10",
//   "createdDate": "2023-08-21T17:08:53",
//   "active": true,
//   "isOnline": false,
//   "gender": 2,
//   "applicationType": 1,
//   "assessment": null,
//   "roles": [
//       {
//           "id": 7,
//           "name": "User"
//       }
//   ],
//   "acceptedCorporateInvition": false
// }