import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  HostListener,
  Inject,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ElementRef,
  OnDestroy
} from "@angular/core";
import { GlobalEventsService } from "./services/global_services/global-events.service";
import { AuthenticationService } from "./services/global_services/authentication.service";
import { SessionService } from "./services/global_services/session.service";
import { NotificationsService } from "./services/notifications/notifications.service";
import { ToastrService } from "ngx-toastr";
import { ClaimRoles, NotificationTypes } from "./common/enums";
import { SessionCallBoxComponent } from "./main_app_components/session-call-box/session-call-box.component";
import { NotificationsAPIService } from "./services/notifications/notifications-api.service";
import { Pagenation } from "./common/pagenation";
import { WINDOW } from "./services/window.service";
import { Router, NavigationStart, NavigationError, ActivatedRoute } from "@angular/router";
import { StickyNotificationsBarComponent } from "./main_app_components/sticky-notifications-bar/sticky-notifications-bar.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { BlogsService } from "./services/blogs/blogs.service";
import { DateHelpers } from "./common/helpers";
import { SessionCheckerService } from "./services/session-checker.service";
import { TranslationService } from "./services/translation.service";
import { TranslateService } from "@ngx-translate/core";
import { DatePipe, DOCUMENT } from "@angular/common";

import { Title, Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';


export enum appointmentType {
  OneToOne = 1,
  OneToMany = 2,
}


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [DatePipe]
})
export class AppComponent implements OnInit, OnDestroy {
  public LoggedUser;
  roles: any[] = []
  title = "coach you";
  @ViewChild("navBar", { static: false }) navBar;
  @ViewChild("stickyNavBar", { static: false }) stickyNavBar;
  @ViewChild('buttonToBeClicked', { static: false }) buttonToBeClicked: ElementRef;

  _NotificationTypes = NotificationTypes;
  IncommingSessionSlot;

  isCommunicatePage: boolean = false;

  isCoach = new BehaviorSubject<boolean>(false);
  isUser = new BehaviorSubject<boolean>(false);

  FooterLatestBlogs = null;

  userData

  UserNotifications: Array<any> = [];
  UserNotificationsPagingModel: Pagenation = { page: 0, rows: 8 };

  public static StaticAccessor;
  public IsMobile = false;

  public IsCMSOpen = false;

  Notification

  paymentMassage;

  public AllNotificationPassed = false;
  public TotalUreadNotificationsCount;

  isShowAssessment: boolean = false;

  baseUrl = environment.baseUrl
  isShowNotificationMinu: boolean = false;

  aiCoachURL

  constructor(
    private _authentication: AuthenticationService,
    private _session: SessionService,
    private _notifications: NotificationsService,
    private _apiNotification: NotificationsAPIService,
    private _router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private _taost: ToastrService,
    private _cd: ChangeDetectorRef,
    private _deviceDetector: DeviceDetectorService,
    private _blogsService: BlogsService,
    private _sessionChecker: SessionCheckerService,
    public _tranalationService: TranslationService,
    public translate: TranslateService,
    private metaTagService: Meta,
    private toastService: ToastrService,
    private http: HttpClient,
    private titleService: Title,
    private activatedRouter: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {



    activatedRouter.queryParams.subscribe(res => {
      if (res.code) {
        this._session.callBack({
          code: res.code,
          appointmentId: +res.state
        }).subscribe(res => {
          this.toastService.error("Your Session has saved in google calendar successfully");
        });
      } else {
      }

    })





    this.userData = this._session.GetUserData()
    AppComponent.StaticAccessor = this;
    this.IsMobile = this._deviceDetector.isMobile();
    this._tranalationService.getLanguage();

    this._router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        let x: string = event.url
        let z = x.includes("communicate")
        if (z) {
          this.IncommingSessionSlot = null
        }
      }
    });
    this.IsLoggedIn() && this.getNotifications();

  }

  ngOnDestroy(): void {
  }

  async ngOnInit(): Promise<void> {
    this.getConfiguration(); 
    this.route.queryParams.subscribe(params => { 
      if (params['appoId']) { 
        let appoId = params['appoId'] 
        
        this.http.get<any>(environment.baseUrl + "Payment/getPaymentStatus/" + appoId).subscribe(
          res => { 
            this.paymentMassage = res
            this.removeParamsFromUrl();
          })
      }

      if (this.isCoach) {
        if (this._session.GetUserData()) {
          this.isShowAssessment = this._session.GetUserData().assessment
        }
      }

    })



    await this.checkRole();



    this.manageRoles();



    this.titleService.setTitle('CoachYou | Build Your Career');
    this.metaTagService.addTags([
      {
        name: 'keywords', content: `coaching, 
                                    coaches, 
                                    coach, 
                                    you, 
                                    professional coaches, 
                                    achieve your goals, 
                                    career, 
                                    business grow your business, 
                                    training, 
                                    workshops,
                                    Coachee,
                                    Career,
                                    Business,
                                    Training,
                                    Marketing,
                                    Sales,
                                    Human resource,
                                    Leadership,
                                    Social media,
                                    Innovation,
                                    Workshops,
                                    IT`
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'CoachYou' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      {
        name: 'description', content: `Build your career, 
                                       Professional coaching, 
                                       Best Coaches, 
                                       Find your coach, 
                                       Marketing Trainer, 
                                       Sales Trainer,
                                       HR Trainer,
                                       Leadership  Trainer,
                                       Business Trainer,
                                       Social media Trainer,
                                       Innovation Trainer,
                                       Coach you` },
      // { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);



    if (window.innerWidth < 995) {
      this.IsMobile = true;
    }
    if (window.innerWidth >= 995) {
      this.IsMobile = false;
    }

    this._populateStartupEvents();
    this._populateFooterBlogs();
    this._initializeSessionChecker(); 
    
    // prevent communications event from firing in CMS
    // this._router.events.subscribe(event => {
    //   StickyNotificationsBarComponent.OnlyInstanceAccessor.IsClosed = false;
    //   if (event instanceof NavigationStart) {
    //     if (event.url.indexOf("communicate") > -1) {
    //       StickyNotificationsBarComponent.OnlyInstanceAccessor.IsClosed = true;
    //     } 
    //   }  
    // }); 


    this.getAppointment();
  }

  closeNav() {
    this.buttonToBeClicked.nativeElement.click();

  }

  dismissPaymentBox() {
    this.paymentMassage = undefined
    // this.removeParamsFromUrl();
  }

  manageRoles() {
    setTimeout(s => {

      if (!this.LoggedUser) return;

      for (let index in this.LoggedUser.roles) {
        this.roles.push(this.LoggedUser.roles[index].name);
      }

      // this.roles.find(x =>{
      //   if(x.includes("User")){
      //     this.isUser.next(true);
      //   }else if(x.includes("Coach")){
      //     this.isCoach.next(true);
      //   }
      // })
    }, 0);
  }

  private _initializeSessionChecker() {
    // this.updateSlots();
  }

  updateSlots() {
    setInterval(() => {
      this._sessionChecker.OnSessionStarted.subscribe(booking => {
        this.IncommingSessionSlot = booking;
      });
    }, 5000)
  }

  addMinutes(numOfMinutes) {
    let date: any = new Date();
    date.setMinutes(date.getMinutes() + numOfMinutes);
    return date;
  }


  getAppointment() {

    let b = this.addMinutes(-60)
    let before30 = this.datePipe.transform(b, 'yyyy-MM-ddTHH:mm:ss');

    let c = this.addMinutes(60)
    let after30 = this.datePipe.transform(c, 'yyyy-MM-ddTHH:mm:ss');



    // 2022-07-19T06:25:57.763Z


    if (this.isCoach.value) {
      let req = {
        from: before30,
        to: after30,
        statuses: [4, 5],
        pageSize: 2,
        pageNumber: 1
      }

      this.http.post<any>(environment.baseUrl + "Coach/Appointment/search", req).subscribe(res => {
        if (res.errorCode == 0) {
          this.IncommingSessionSlot = res.data.data || []
          this._cd.detectChanges()
          return

          if (res.data.data && res.data.data.length) {
            if (res.data.data.length > 1) {
              this.IncommingSessionSlot = this.resolveNearestAppointment(res.data.data);
            } else {
              this.IncommingSessionSlot = res.data.data;
            }
          } else {
            this.IncommingSessionSlot = res.data.data;
          }

          console.warn("sessionsHQ", this.IncommingSessionSlot)

          // if (res.data.data && res.data.data.length) {
          //   this.IncommingSessionSlot = res.data.data.slice(0, 1);
          // } else {
          //   this.IncommingSessionSlot = res.data.data;
          // }

        }
      })


      if (!location.href.includes("communicate")) {
        setInterval(() => {
          this.http.post<any>(environment.baseUrl + "Coach/Appointment/search", req).subscribe(res => {
            if (res.errorCode == 0) {
              this.IncommingSessionSlot = res.data.data
              this._cd.detectChanges()
              return
              if (res.data.data && res.data.data.length) {
                debugger
                if (res.data.data.length > 1) {
                  this.IncommingSessionSlot = this.resolveNearestAppointment(res.data.data);
                } else {
                  this.IncommingSessionSlot = res.data.data; 
                }
              } else {
                this.IncommingSessionSlot = res.data.data;
              }
            }
          })
        }, 30000)
      }

    }

    if (this.isUser.value) {
      let req = {
        from: before30,
        to: after30,
        statuses: [5],
        pageSize: 2,
        pageNumber: 1
      }
      this.http.post<any>(environment.baseUrl + "Appointment/search", req).subscribe(res => {
        if (res.errorCode == 0) {
          this.IncommingSessionSlot = res.data.data || []
          this._cd.detectChanges()
          return;
          if (res.data.data && res.data.data.length) {
            if (res.data.data.length > 1) {
              this.IncommingSessionSlot = this.resolveNearestAppointment(res.data.data);
            } else {
              this.IncommingSessionSlot = res.data.data;
            }
          } else {
            this.IncommingSessionSlot = res.data.data;
          }
          this._cd.detectChanges()
        }
      })

      if (!location.href.includes("communicate")) {
        setInterval(() => {
          this.http.post<any>(environment.baseUrl + "Appointment/search", req).subscribe(res => {
            if (res.errorCode == 0) {
              this.IncommingSessionSlot = res.data.data || []
              this._cd.detectChanges()
              return;
              if (res.data.data && res.data.data.length) {
                if (res.data.data.length > 1) {
                  this.IncommingSessionSlot = this.resolveNearestAppointment(res.data.data);
                } else {
                  this.IncommingSessionSlot = res.data.data;
                }
              } else {
                this.IncommingSessionSlot = res.data.data;
              }
              this._cd.detectChanges()
            }
          })
        }, 30000)
      }

    }
  }

  resolveNearestAppointment(appointments: any[]) {
    // #Hamza >> this function reorder the appointments array from the nearest appointment to the farthest according to Time Now
    const now = new Date();
    appointments.sort((a, b) => {
      const timeDiffA = Math.abs(new Date(a.appointmentDate).getTime() - now.getTime());
      const timeDiffB = Math.abs(new Date(b.appointmentDate).getTime() - now.getTime());
      return timeDiffA - timeDiffB;
    });
    return appointments
  }

  private _populateFooterBlogs() {
    this._blogsService.GetPinnedBlogs().subscribe(response => {
      this.FooterLatestBlogs = response.data.data;
    });
  }

  public ShortendedDate(date) {
    return
    date = new Date(date);
    return DateHelpers.FormatDateMonthShortented(date);
  }

  // sticky nav bar on scroll
  navIsFixed = false;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (number > 112) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && number < 10) {
      this.navIsFixed = false;
    }
  }

  private _populateStartupEvents() {
    if (this._authentication.isLoggedUser()) {
      this.LoggedUser = this._session.GetUserData();
      this._populateUserNotifications();
    }
    // login hook
    GlobalEventsService.UserLoggedIn.subscribe(LogingData => {
      this.LoggedUser = LogingData;
      // this._initializeSessionChecker();

    });

    // logout hook
    GlobalEventsService.UserLoggedOut.subscribe(() => {
      this.LoggedUser = null;
    });
  }

  private _populateUserNotifications() {
  }

  public CollapseNavBar() {
    return
    if (this.navBar && this.stickyNavBar) {
      this.navBar.nativeElement.classList.remove("show");
      this.stickyNavBar.nativeElement.classList.remove("show");
    }
    if (this.navBar) {
      this.navBar.nativeElement.classList.remove("show");
    }
  }

  logout() {
    this._notifications.Disconnect();
    this._router.navigate(['/'])
    this._authentication.logout();
    setTimeout(() => {
      location.reload();
    }, 1000)
  }

  isRtl() {
    return this._tranalationService.isRtl();
  }
  isArabic() {
    var lang = this._tranalationService.getLanguage();
    if (lang == "ar") return true;

    return false;
  }
  getLanguage() {
    return this._tranalationService.getLanguage();
  }
  setPrefLang(value) {
    this._tranalationService.setLang(value);

    setTimeout(s => {
      location.reload()
    }, 500)

  }

  navLinkClicked(event) {
    this._resetActiveNavLink();
    let targetItem = event.target.closest(".nav-item");
    targetItem.classList.add("active");
  }
  checkWindowSize() {
    if (window.innerWidth < 989) {
      this.IsMobile == true;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (window.innerWidth < 995) {
      this.IsMobile = true;
    }
    if (window.innerWidth >= 995) {
      this.IsMobile = false;
    }
  }
  private _resetActiveNavLink() {
    let items = this.navBar.nativeElement.querySelectorAll(".nav-item");
    for (var i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
    }

    // let stickyItems = this.stickyNavBar.nativeElement.querySelectorAll(".nav-item");
    // for (var i = 0; i < stickyItems.length; ++i) {
    //   stickyItems[i].classList.remove("active");
    // }
  }

  public CurrentYear() {
    return new Date().getFullYear();
  }

  public IsLoggedCoach() {
    if (this.LoggedUser) {
      return this.roles.includes("Coach")
    }
  }

  public IsLoggedUser() {
    if (this.LoggedUser)
      return this.roles.includes("User");
  }
  public IsLoggedIn() {
    if (this.LoggedUser) return true;
    return false;
  }

  @HostListener("scroll", ["$event"]) notificationScrolled(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.UserNotificationsPagingModel.page++;
      // this._populateUserNotifications();
    }
  }

  checkImageUrl(event) {
    event.srcElement.src =
      "https://coachyou.co/uploads/system_images/no_image_available.png";
  }


  async checkRole() {
    if (this.getUserData() && this.getUserData() && this.getUserData().roles) {
      if (this.getUserData().roles.some(function (o) { return o["name"] == "User" })) {
        this.isUser.next(true);
      } else if (this.getUserData().roles.some(function (o) { return o["name"] == "Coach" })) {
        this.isCoach.next(true);
      }
    }
  }

  getUserData = (): any => JSON.parse(localStorage.getItem("UserData"));


  removeParamsFromUrl() {
    this._router.navigate(['/']);
  }



  checkIfPageIsCommunicatePage = () => {

  }


  handleShowingMenu = () => {
    this.isShowNotificationMinu = !this.isShowNotificationMinu;
    this.getNotifications();
  }


  getNotifications = () => {

    let pageSize = 10;
    let pageNumber = 1;
    this._notifications.getNotification(pageSize, pageNumber).subscribe(res => {
      this.Notification = res.data.data.map(notification => {
        let s = {
          title: notification.title,
          body: notification.body,
          createdAt: notification.createdAt,
        }
        return s
      })
    })
  }


  private dateFormatted(date) {
    if (!date) return
    let newDate;
    if (typeof date === "string") {
      newDate = date
        .replace(/-/g, "/")
        .replace(/T/g, " ")
        .substring(0, date.length - 4);
    }
    return DateHelpers.DateFormatted(newDate ? new Date(newDate) : date);
  }

  private timeFormatted(date) {
    if (!date) return
    let newDate;
    if (typeof date === "string") {
      newDate = date
        .replace(/-/g, "/")
        .replace(/T/g, " ")
        .substring(0, date.length - 4);
    }
    return DateHelpers.TimeFormatted(newDate ? new Date(newDate) : date);
  }


  goToExternalLink = () => {
    if (this.LoggedUser) {
      this.window.open("https://ai-career.coachyou.io/?token=" + this.getUserData().token)
    } else {
      this.window.open("https://ai-career.coachyou.io/")
    }
  }

  getConfiguration = () => {
    this.http.get<any>(environment.baseUrl + "Configuration").subscribe(res => {
      this.aiCoachURL = res.data.configSetting.aiCoachURL
    })
  }




}

